import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import SongLoveDetails from './SongLoveDetails.JPG'; // Image from 华晨宇工作室微博
import './NewWorldStyle.css';


function SongLove() {
	let navigate = useHistory();

	const onClick = () => {
		if (window.history.state) {
			window.history.back();
		}
		else {
			navigate.push('/');
		}
	}

	return (
		<Fragment>
			<div class="SongContainer">
				<p class="fontShadow loveDetails loveDetails1 font">“ 独 木 桥 呀</p>
				<p class="fontShadow loveDetails loveDetails2 font7">把 谁 推 下</p>
				<p class="fontShadow loveDetails loveDetails3 font3">才 算 赢 家 ”</p>
				<p class="fontShadow loveDetails loveDetails4 font2">“ 伤 疤</p>
				<p class="fontShadow loveDetails loveDetails5 font7">就 丢 给 回 忆 吧 ”</p>
				<p class="fontShadow loveDetails loveDetails6 font6">“ 当</p>
				<p class="fontShadow loveDetails loveDetails7 font5">阳 光</p>
				<p class="fontShadow loveDetails loveDetails8 font7">败 给 阴 霾</p>
				<p class="fontShadow loveDetails loveDetails9 font1">没 想 到 你 会 拼 命 为 我</p>
				<p class="fontShadow loveDetails loveDetails10 font5">拨 开 ”</p>
				<p class="fontShadow loveDetails loveDetails11 font">“ 当 你 的 笑 容</p>
				<p class="fontShadow loveDetails loveDetails12 font5">绽 开</p>
				<p class="fontShadow loveDetails loveDetails13 font7">这 世 界 突 然 填 满</p>
				<p class="fontShadow loveDetails loveDetails14 font3">色 彩 ”</p>
				<img class="home NewWorldAlbum" src={SongLoveDetails} alt="SongLoveDetails"/>
				<p class="fontShadow loveDetails loveDetails15 font7">《 好 想 爱 这 个 世 界 啊 》</p>
				<button class="backButton font3" onClick={onClick}>回</button>
			</div>
		</Fragment>
	)
}

export { SongLove }