import React, { Fragment, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
// import Light from './Light.JPG'; // Image from 华晨宇工作室微博

function About() {
	let navigate = useHistory();
	const [ languageSwitch, setLanguageSwitch] = useState(false);

	const onClick = () => {
		navigate.push('/');
	}

	const toEnglish = () => {
		if (languageSwitch) {
			document.getElementById('switch').innerHTML = "To English";
			document.getElementById('switch').classList.remove("font4");
			document.getElementById('note').classList.remove('noteSpace');
			document.getElementById('note').classList.add('detailsMargins');
			document.getElementById('message').classList.remove("font9");
			setLanguageSwitch(false);

			let messageAbout = "<br>如果你也找到了这里 那也不必多说了<br>“ 欢 迎 回 家 ” <br>是你最想听的吧<br><br>守护心里那朵花<br>创建一个安静的世界<br>专属于这个“火星家庭” <br><br>没人打扰<br>只有花火";
			document.getElementById('message').innerHTML = messageAbout;

			let note = "*本站不是官方网站，只是歌迷空间<br>如有任何问题或建议，欢迎随时联系 <br> <a class='horizontalWriting font4' href='mailto:welcome@huachenyuworld.com' target='_blank' rel='noopener noreferrer'>welcome@huachenyuworld.com</a> 哦！<br>0 5 2 5";
			document.getElementById('note').innerHTML = note;
		}
		else {
			document.getElementById('switch').innerHTML = "中文";
			document.getElementById('switch').classList.add("font4");
			document.getElementById('note').classList.add('noteSpace'); 
			document.getElementById('note').classList.remove('detailsMargins');
			document.getElementById('message').classList.add("font9");
			setLanguageSwitch(true);

			let messageAbout = "<br>If you also found your way here <br><br>then “ welcome home ”<br><br><br>A quiet place<br> for all of us<br><br>and<br><br>our star";
			document.getElementById('message').innerHTML = messageAbout;

			let note = "* This is only a fan space,<br>not an offical website for Huachenyu<br>If you have any questions or suggestions, <br>feel free to contact <br><a class='horizontalWriting font4' href='mailto:welcome@huachenyuworld.com' target='_blank' rel='noopener noreferrer'>welcome@huachenyuworld.com</a>!<br>0525";
			document.getElementById('note').innerHTML = note;
		}
		
	}

	return (
		<Fragment>
			<div class="containerAbout">
				{/*<img class="home" src={Light} alt="Light"/>*/}
				<button class="backButton" onClick={onClick}>回家</button>
				<p class="aboutMessage messageMargins" id="message">
				<br></br>
				如果你也找到了这里 那也不必多说了
				<br></br>
				“ 欢 迎 回 家 ” 
				<br></br>
				是你最想听的吧
				<br></br>
				<br></br>
				守护心里那朵花
				<br></br>
				创建一个安静的世界
				<br></br>
				专属于这个“火星家庭” 
				<br></br><br></br>
				没人打扰
				<br></br>
				只有花火
				</p>
				<p class="aboutDetails detailsMargins" id="note">
				*本站不是官方网站，只是歌迷空间
				<br></br>
				如有任何问题或建议，欢迎随时联系 <br></br>
				<a class='horizontalWriting font4' href="mailto:welcome@huachenyuworld.com" target="_blank" rel="noopener noreferrer">welcome@huachenyuworld.com</a> 哦！
				<br></br>
				0 5 2 5
				</p>
				<Link class="aboutDetails horizontalMargin7 buttonFont" id="switch" onClick={toEnglish}>
				To English
				</Link>

			</div>
		</Fragment>
	)
}

export { About }