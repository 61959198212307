import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import SongBullfightDetails from './SongBullfightDetails.JPG'; // Image from 华晨宇工作室微博
import './NewWorldStyle.css';


function SongBullfight() {
	let navigate = useHistory();

	const onClick = () => {
		if (window.history.state) {
			window.history.back();
		}
		else {
			navigate.push('/');
		}
	}

	return (
		<Fragment>
			<div class="SongContainer">
				<p class="fontShadow songDetails songDetails1 font1">“ 近 看</p>
				<p class="fontShadow songDetails songDetails2 font7">放 大 镜 下 的 人 在</p>
				<p class="fontShadow songDetails songDetails3 font5">逃 窜 ”</p>
				<p class="fontShadow songDetails songDetails4 font7">“ 试 探</p>
				<p class="fontShadow songDetails songDetails5 font">我 们 的 叫 声 有 多</p>
				<p class="fontShadow songDetails songDetails6 font5">尖 ”</p>
				<p class="fontShadow songDetails songDetails7 font3">“ 太</p>
				<p class="fontShadow songDetails songDetails8 font">混 乱</p>
				<p class="fontShadow songDetails songDetails9 font6">生 命 本 就 该</p>
				<p class="fontShadow songDetails songDetails10 font5">循 环 ”</p>
				<img class="home NewWorldAlbum" src={SongBullfightDetails} alt="SongBullfightDetails"/>
				<p class="fontShadow songDetails songDetails11 font">《 斗 牛 》</p>
				<button class="backButton font3" onClick={onClick}>回</button>
			</div>
		</Fragment>
	)
}

export { SongBullfight }