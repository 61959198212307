import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import NewWorldAlbum from './NewWorldAlbum.JPG'; // Image from 华晨宇工作室微博
// import NewWorldHere from './NewWorldHere.JPG'; // Image from 华晨宇工作室微博
import './NewWorldAlbumStyle.css';

// function switchCover(newWorld) {
// 	newWorld.setAttribute('src', NewWorldHere);
// }

function NewWorld() {
	let navigate = useHistory();

	const intoNewWorld = () => {
		navigate.push('/NewWorld');
	}

	const onClick = () => {
		if (window.history.state) {
			window.history.back();
		}
		else {
			navigate.push('/');
		}
	}

	return (
		<Fragment>
			<div class="containerNewWorld fontShadow">
				<p class="fontShadow NewWorldAlbumLyrics NewWorldAlbumLyrics1 font">野 性 袒 露 着 灵 魂 纯 粹</p>
				<p class="fontShadow NewWorldAlbumLyrics NewWorldAlbumLyrics2 font2">都 奔 向 白 昼</p>
				<p class="fontShadow NewWorldAlbumLyrics NewWorldAlbumLyrics3 font7">太 混 乱</p>
				<p class="fontShadow NewWorldAlbumLyrics NewWorldAlbumLyrics4 font3">性 向 善</p>
				<p class="fontShadow NewWorldAlbumLyrics NewWorldAlbumLyrics5 font">或 者 肆 意 妄 为</p>
				<p class="fontShadow NewWorldAlbumLyrics NewWorldAlbumLyrics6 font7">混 沌 初 开</p>
				<p class="fontShadow NewWorldAlbumLyrics NewWorldAlbumLyrics7 font5">清 浊 黑 白</p>
				<p class="fontShadow NewWorldAlbumLyrics NewWorldAlbumLyrics8 font1">千 万 年 轮 回 着</p>
				<p class="fontShadow NewWorldAlbumLyrics NewWorldAlbumLyrics9 font3">浮 生 百 态</p>
				<p class="fontShadow NewWorldAlbumLyrics NewWorldAlbumLyrics10 font7">太 多 生 命 无 一 幸 免</p>
				<p class="fontShadow NewWorldAlbumLyrics NewWorldAlbumLyrics11 font2">都 失 控</p>
				<p class="fontShadow NewWorldAlbumLyrics NewWorldAlbumLyrics12 font5">古</p>
				<p class="fontShadow NewWorldAlbumLyrics NewWorldAlbumLyrics13 font5">今</p>
				<p class="fontShadow NewWorldAlbumLyrics NewWorldAlbumLyrics14 font5">天</p>
				<p class="fontShadow NewWorldAlbumLyrics NewWorldAlbumLyrics15 font5">地</p>
				<p class="fontShadow NewWorldAlbumLyrics NewWorldAlbumLyrics16 font">当 我 再 度 毁 灭 后</p>
				<p class="fontShadow NewWorldAlbumLyrics NewWorldAlbumLyrics17 font3">一 切 变 更 纯 净</p>

				<img class="NewWorldAlbumCover" src={NewWorldAlbum} alt="NewWorldAlbum" onClick={intoNewWorld}/>
				<p class="fontShadow NewWorldAlbumLyrics NewWorldAlbumLyrics37 font6 horizontalWriting"><i>[ 点击专辑封面进入新世界 ]</i></p>

				<p class="fontShadow NewWorldAlbumLyrics NewWorldAlbumLyrics18 font7">直 到</p>
				<p class="fontShadow NewWorldAlbumLyrics NewWorldAlbumLyrics19 font3">迷 失</p>
				<p class="fontShadow NewWorldAlbumLyrics NewWorldAlbumLyrics20 font2">自 己</p>
				<p class="fontShadow NewWorldAlbumLyrics NewWorldAlbumLyrics21 font">万 物 皆 只 因 我</p>
				<p class="fontShadow NewWorldAlbumLyrics NewWorldAlbumLyrics22 font5">一 念 花 开</p>
				<p class="fontShadow NewWorldAlbumLyrics NewWorldAlbumLyrics23 font">认 真 只 是 我</p>
				<p class="fontShadow NewWorldAlbumLyrics NewWorldAlbumLyrics24 font2">不 如 下 山</p>
				<p class="fontShadow NewWorldAlbumLyrics NewWorldAlbumLyrics25 font">笑 看 万 物 是 假 象</p>
				<p class="fontShadow NewWorldAlbumLyrics NewWorldAlbumLyrics26 font">别 怕</p>
				<p class="fontShadow NewWorldAlbumLyrics NewWorldAlbumLyrics27 font5">别 怕</p>
				<p class="fontShadow NewWorldAlbumLyrics NewWorldAlbumLyrics28 font7">夕 阳 西 下</p>
				<p class="fontShadow NewWorldAlbumLyrics NewWorldAlbumLyrics29 font1">接 通 电 话</p>
				<p class="fontShadow NewWorldAlbumLyrics NewWorldAlbumLyrics30 font5">是 你 啊</p>
				<p class="fontShadow NewWorldAlbumLyrics NewWorldAlbumLyrics31 font">你 就 是 你</p>
				<p class="fontShadow NewWorldAlbumLyrics NewWorldAlbumLyrics32 font1">最 好 的 你</p>
				<p class="fontShadow NewWorldAlbumLyrics NewWorldAlbumLyrics33 font5">是 我 的 唯 一</p>
				<p class="fontShadow NewWorldAlbumLyrics NewWorldAlbumLyrics34 font1">从 开 始</p>
				<p class="fontShadow NewWorldAlbumLyrics NewWorldAlbumLyrics35 font7">到</p>
				<p class="fontShadow NewWorldAlbumLyrics NewWorldAlbumLyrics36 font5">永 久</p>
				
				<button class="albumHome font" onClick={onClick}>回</button>
				
			</div>
		</Fragment>
	)
}

export { NewWorld }