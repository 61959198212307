import React, { Fragment } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Found from './Found.JPG'; // Image from 华晨宇工作室微博

function Redirect() {
	let navigate = useHistory();

	const gotoAbout = () => {
		navigate.push('/');
	}

	const onClick = () => {
		if (window.history.state) {
			window.history.back();
		}
		else {
			navigate.push('/');
		}
	}

	return (
		<Fragment>
			<div class="containerRedirect">
				<img class="home" src={Found} alt="Found"/>
				<button class="backButton font3" onClick={onClick}>回</button>
				<p class="fontShadow redirectMessage redirectMessage1 font1">“ 他</p>
				<p class="fontShadow redirectMessage redirectMessage2 font7">忘 记 了</p>
				<p class="fontShadow redirectMessage redirectMessage3 font">回 家 的 路</p>
				<p class="fontShadow redirectMessage redirectMessage4 font6">街 上 只 剩 他</p>
				<p class="fontShadow redirectMessage redirectMessage5 font3">一 个 人</p>
				<p class="fontShadow redirectMessage redirectMessage6 font5">游荡 ”</p>
				<p class="fontShadow redirectMessage redirectMessage7 font7">《我们都是孤独的》</p>
				<p class="fontShadow redirectNote font7">4 0 4<br></br>你怎么走到这里啦<br></br>你想找的还在创作中哦</p>
				<p class="message">本站内容出自华晨宇专辑和华晨宇相关周边</p>
				<Link class="message about" to="/about" onClick={gotoAbout}>关于本站</Link>
			</div>
		</Fragment>
	)
}

export { Redirect }