import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import Red from './Red.JPG'; // Image from 华晨宇工作室微博

function Quotes() {
	let navigate = useHistory();

	const onClick = () => {
		navigate.push('/');
	}

	return (
		<Fragment>
			<div class="containerQuotes">
				<img class="home" src={Red} alt="Red"/>
				<button class="backButton font" onClick={onClick}>回家</button>

				<p class="aboutMessage horizontalMargin">
				<br></br><br></br><br></br><br></br><br></br>
				<br></br><br></br><br></br><br></br>
				"我只追求自己没有烦恼" 
				</p>
				<p class="aboutMessage horizontalMargin font2">
				<br></br><br></br><br></br><br></br>
				"我并不在意别人对我的夸奖，我也不在意别人对我的批评"
				</p>
				<p class="aboutMessage horizontalMargin font horizontalMargin6">
				<br></br><br></br><br></br>
				"轻轻松松哒, 随意就好"
				</p>
				<p class="aboutMessage horizontalMargin font">
				<br></br><br></br><br></br><br></br>
				<br></br><br></br><br></br><br></br>
				<br></br><br></br><br></br><br></br>
				<br></br><br></br>
				"你自己想的什么东西 就直接说 
				<br></br>
				一个前提就是
				</p>
				<p class="aboutMessage horizontalMargin horizontalMargin6">
				<br></br><br></br><br></br><br></br>
				<br></br><br></br><br></br><br></br>
				<br></br><br></br><br></br><br></br>
				<br></br>
				你说出来的东西 
				<br></br>
				一定要是建立在一个善良的基础上"
				</p>

				<p class="aboutMessage horizontalMargin1">
				<br></br><br></br><br></br><br></br><br></br>
				<br></br><br></br><br></br>
				"不是我变了 而是
				<br></br>
				所有人都变了
				</p>
				<p class="aboutMessage horizontalMargin1 font5">
				<br></br><br></br><br></br><br></br><br></br>
				<br></br>
				而我没有变”
				</p>

				<p class="aboutMessage horizontalMargin2">
				<br></br>
				"我们彼此都照顾好自己"
				</p>
				<p class="aboutMessage horizontalMargin2 font8">
				<br></br><br></br><br></br>
				"你们一直红 我就一直红"
				</p>

				<p class="aboutMessage horizontalMargin3">
				<br></br><br></br><br></br>
				“我在意你们，把我当做家人”
				</p>
				<p class="aboutMessage horizontalMargin3 font3">
				<br></br><br></br><br></br><br></br><br></br>
				<br></br><br></br><br></br>
				“谢谢你们还在，我的国”
				</p>

				<p class="aboutMessage horizontalMargin4">
				<br></br><br></br><br></br><br></br>
				<br></br><br></br><br></br><br></br>
				“那我不需要利用别人，我干嘛让别人来利用我呢”
				</p>
				<p class="aboutMessage horizontalMargin4 font">
				<br></br><br></br><br></br><br></br><br></br>
				<br></br><br></br><br></br><br></br><br></br>
				<br></br><br></br><br></br><br></br>
				“这个世界它其实限制了你很多，但它其实也给了你很多
				<br></br>
				我既然已经都接受了它限制我的东西了 我为什么不去接受它给我的东西呢”
				</p>
				<p class="aboutMessage horizontalMargin4">
				“我不是一个天才，我只是努力的时候比别人要早一些”
				</p>

				<p class="aboutMessage horizontalMargin5 font7">
				<br></br><br></br><br></br><br></br>
				"希望让大家在喜欢我的时候 没有太多的烦恼就可以了"
				</p>
				<p class="aboutMessage horizontalMargin5">
				<br></br><br></br><br></br><br></br><br></br>
				“流眼泪是一件很美好的事情啊”
				</p>

				<p class="aboutDetails detailsMargins">
				还记得那些年华晨宇说过的话
				<br></br>
				。。。这页又成功把我给弄哭了
				<br></br>
				0 5 2 5
				</p>

			</div>
		</Fragment>
	)
}

export { Quotes }