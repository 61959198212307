import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import GiftAlbum from './GiftAlbum.JPG'; // Image from https://baike.baidu.com/item/卡西莫多的礼物/15703232
import './NewWorldAlbumStyle.css';

function Gift() {
	let navigate = useHistory();

	const intoGiftAlbum = () => {
		navigate.push('/Gift');
	}

	const onClick = () => {
		if (window.history.state) {
			window.history.back();
		}
		else {
			navigate.push('/');
		}
	}

	return (
		<Fragment>
			<div class="containerGift fontShadow">
				<p class="fontShadow GiftAlbumLyrics GiftAlbumLyrics1 font1">他</p>
				<p class="fontShadow GiftAlbumLyrics GiftAlbumLyrics2 font6">厌 倦 了</p>
				<p class="fontShadow GiftAlbumLyrics GiftAlbumLyrics3 font">所 以 活 在</p>
				<p class="fontShadow GiftAlbumLyrics GiftAlbumLyrics4 font3">自 己 的 世 界</p>
				<p class="fontShadow GiftAlbumLyrics GiftAlbumLyrics5 font6">里 面</p>
				<p class="fontShadow GiftAlbumLyrics GiftAlbumLyrics6 font">无 人 过 问</p>
				<p class="fontShadow GiftAlbumLyrics GiftAlbumLyrics7 font7">拜 托 来 一 束</p>
				<p class="fontShadow GiftAlbumLyrics GiftAlbumLyrics8 font5">火 花</p>
				<p class="fontShadow GiftAlbumLyrics GiftAlbumLyrics9 font">我 会</p>
				<p class="fontShadow GiftAlbumLyrics GiftAlbumLyrics10 font3">一 路 奉 陪</p>
				<p class="fontShadow GiftAlbumLyrics GiftAlbumLyrics11 font1">生 活 是 种 借 口</p>
				<p class="fontShadow GiftAlbumLyrics GiftAlbumLyrics12 font7">胁 迫 人 放 弃</p>
				<p class="fontShadow GiftAlbumLyrics GiftAlbumLyrics13 font2">一 切 拥 有</p>
				<p class="fontShadow GiftAlbumLyrics GiftAlbumLyrics14 font5">何 必</p>
				<p class="fontShadow GiftAlbumLyrics GiftAlbumLyrics15 font">妥 协 迁 就</p>
				<p class="fontShadow GiftAlbumLyrics GiftAlbumLyrics16 font2">只 有 我</p>
				<p class="fontShadow GiftAlbumLyrics GiftAlbumLyrics17 font7">守 着 安 静 的 沙 漠</p>
				<p class="fontShadow GiftAlbumLyrics GiftAlbumLyrics18 font5">等 待 着 花 开</p>
				<p class="fontShadow GiftAlbumLyrics GiftAlbumLyrics19 font7">我 该</p>
				<p class="fontShadow GiftAlbumLyrics GiftAlbumLyrics20 font2">不 朽</p>
				
				<img class="GiftAlbumCover" src={GiftAlbum} alt="GiftAlbum" onClick={intoGiftAlbum}/>
				<p class="fontShadow NewWorldAlbumLyrics NewWorldAlbumLyrics37 font6 horizontalWriting"><i>[ 点击专辑封面进入新世界 ]</i></p>

				<p class="fontShadow GiftAlbumLyrics GiftAlbumLyrics21 font2">能 不 能 慢 下 来</p>
				<p class="fontShadow GiftAlbumLyrics GiftAlbumLyrics22 font7">没 人 打 扰 的 地 方</p>
				<p class="fontShadow GiftAlbumLyrics GiftAlbumLyrics23 font4 font1">Why Nobody Fights</p>
				<p class="fontShadow GiftAlbumLyrics GiftAlbumLyrics24 font">也 许 我 是 一 道</p>
				<p class="fontShadow GiftAlbumLyrics GiftAlbumLyrics25 font6">微 光</p>
				<p class="fontShadow GiftAlbumLyrics GiftAlbumLyrics26 font7">我 也 会</p>
				<p class="fontShadow GiftAlbumLyrics GiftAlbumLyrics27 font5">绽 放</p>
				<p class="fontShadow GiftAlbumLyrics GiftAlbumLyrics28 font">回 荡 夜 空</p>
				<p class="fontShadow GiftAlbumLyrics GiftAlbumLyrics29 font3">我 只 为</p>
				<p class="fontShadow GiftAlbumLyrics GiftAlbumLyrics30 font">寻 找 到 真 实 而</p>
				<p class="fontShadow GiftAlbumLyrics GiftAlbumLyrics31 font5">活</p>
				<p class="fontShadow GiftAlbumLyrics GiftAlbumLyrics32 font7">别 靠 近 我</p>
				<p class="fontShadow GiftAlbumLyrics GiftAlbumLyrics33 font5">一 个 人</p>
				<p class="fontShadow GiftAlbumLyrics GiftAlbumLyrics34 font">发 呆</p>
				<p class="fontShadow GiftAlbumLyrics GiftAlbumLyrics35 font7">今 晚 月 亮 也 想 睡 了</p>
				<p class="fontShadow GiftAlbumLyrics GiftAlbumLyrics36 font2">月 光 为 我 伴 舞</p>
				
				<button class="albumHome font" onClick={onClick}>回</button>
				
			</div>
		</Fragment>
	)
}

export { Gift }