import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import SongHouseDetails from './SongHouseDetails.JPG'; // Image from 华晨宇工作室微博
import './NewWorldStyle.css';


function SongHouse() {
	let navigate = useHistory();

	const onClick = () => {
		if (window.history.state) {
			window.history.back();
		}
		else {
			navigate.push('/');
		}
	}

	return (
		<Fragment>
			<div class="SongContainer">
				<p class="fontShadow houseDetails houseDetails1  font1">“ 默 默 享 受</p>
				<p class="fontShadow houseDetails houseDetails2  font7">就 算 只 有</p>
				<p class="fontShadow houseDetails houseDetails3  font6">那 片 刻</p>
				<p class="fontShadow houseDetails houseDetails4  font5">自 由 ”</p>
				<p class="fontShadow houseDetails houseDetails5  font7">“ 白 色 时 空 背 景</p>
				<p class="fontShadow houseDetails houseDetails6  font6">不 断</p>
				<p class="fontShadow houseDetails houseDetails7  font3">循 环</p>
				<p class="fontShadow houseDetails houseDetails8  font7">的 语 句 ”</p>
				<p class="fontShadow houseDetails houseDetails9  font7">“ 在 半</p>
				<p class="fontShadow houseDetails houseDetails10 font5">梦</p>
				<p class="fontShadow houseDetails houseDetails11 font6">的 半</p>
				<p class="fontShadow houseDetails houseDetails12 font3">醒</p>
				<p class="fontShadow houseDetails houseDetails13 font7">的 夜 里</p>
				<p class="fontShadow houseDetails houseDetails14 font">矛 盾 的 就 快 要</p>
				<p class="fontShadow houseDetails houseDetails15 font5">窒 息 ”</p>
				<p class="fontShadow houseDetails houseDetails16 font6">“ 站 在 角 落 中</p>
				<p class="fontShadow houseDetails houseDetails17 font">享 受 片 刻 的</p>
				<p class="fontShadow houseDetails houseDetails18 font5">自 由 ”</p>
				<img class="home NewWorldAlbum" src={SongHouseDetails} alt="SongHouseDetails"/>
				<p class="fontShadow houseDetails houseDetails19 font7">《 疯 人 院 》</p>
				<button class="backButton font3" onClick={onClick}>回</button>
			</div>
		</Fragment>
	)
}

export { SongHouse }