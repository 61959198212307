import React, { Fragment } from 'react';
import { Link, useHistory } from 'react-router-dom';
// import world from './world.JPG'; // Image from 华晨宇工作室微博

// var index = 0;
// var welcome = " welcome home! ❤";

// function say() {
// 	if (index < welcome.length) {
// 		document.getElementById('welcome').innerHTML += welcome[index];
// 		setTimeout(say, 100);
// 		index++;
// 	}
// }

function Home() {
	let navigate = useHistory();

	const gotoAlbumNewWorld = () => {
		navigate.push('/AlbumNewWorld');
	}

	const gotoAlbumH = () => {
		navigate.push('/AlbumH');
	}

	const gotoAlbumAliens = () => {
		navigate.push('/AlbumAliens');
	}

	const gotoAlbumGift = () => {
		navigate.push('/AlbumGift');
	}

	const gotoAbout = () => {
		navigate.push('/');
	}

	const gotoQuotes = () => {
		navigate.push('/hcy');
	}

	// const goto148 = () => {
	// 	navigate.push('/148');
	// }

	// const gotoAce = () => {
	// 	navigate.push('/ace');
	// }

	const gotoLove = () => {
		navigate.push('/BORNToLove');
	}

	return (
		<Fragment>
			{/*<link rel="stylesheet" media="screen and (max-width: 844px)" href="mobile.css"></link>*/}
			<div class="container">
				<button class="backButton font" id="albumNewWorld" onClick={gotoAlbumNewWorld}>《 新世界 》</button>
				<button class="backButton font" id="albumH" onClick={gotoAlbumH}>《 H 》</button>
				<button class="backButton font" id="albumAliens" onClick={gotoAlbumAliens}>《 异类 》</button>
				<button class="backButton font" id="albumGift" onClick={gotoAlbumGift}>《 卡西莫多<br></br>的礼物 》</button>
				<button class="backButton gotoButton ToLove" onClick={gotoLove}>BORN TO LOVE</button>
				<button class="backButton gotoButton" onClick={gotoQuotes}>HCY 语录</button>
				{/*<button class="backButton buttonFont gotoButton" onClick={goto148}>hcy<br></br>148</button>
				<button class="backButton gotoButton button3" onClick={gotoAce}>王牌</button>*/}
				{/*<img class="home" src={world} alt="world"/>*/}
				<h2 class="welcome fontClass fontShadow" id="welcome">“欢迎回家”</h2>
				<h2 class="music fontClass fontShadow">NEW WORLD</h2>
				<p class="message">温馨提示：横屏体验更佳哦~ <br></br>本站内容出自华晨宇专辑和华晨宇相关周边</p>
				<Link class="message about" to="/about" onClick={gotoAbout}>关于本站</Link>
			</div>
		</Fragment>
	)
}

export { Home }