import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import SongNewWorldDetails from './SongNewWorldDetails.JPG'; // Image from 华晨宇工作室微博
import './NewWorldStyle.css';


function SongNewWorld() {
	let navigate = useHistory();

	const onClick = () => {
		if (window.history.state) {
			window.history.back();
		}
		else {
			navigate.push('/');
		}
	}

	return (
		<Fragment>
			<div class="SongContainer">
				<p class="fontShadow newWorldDetails newWorldDetails1  font">“ 凛 冬 过 后 人 事</p>
				<p class="fontShadow newWorldDetails newWorldDetails2  font6">剩 几 何</p>
				<p class="fontShadow newWorldDetails newWorldDetails3  font3">迎 来 送 往</p>
				<p class="fontShadow newWorldDetails newWorldDetails4  font7">台 下</p>
				<p class="fontShadow newWorldDetails newWorldDetails5  font5">宾 客 ”</p>
				<p class="fontShadow newWorldDetails newWorldDetails6  font">“ 看 太 行</p>
				<p class="fontShadow newWorldDetails newWorldDetails7  font3">历 风 霜</p>
				<p class="fontShadow newWorldDetails newWorldDetails8  font6">我 孤 勇 前 进</p>
				<p class="fontShadow newWorldDetails newWorldDetails9  font">万 古 腔</p>
				<p class="fontShadow newWorldDetails newWorldDetails10 font5">任 我 唱 ”</p>
				<p class="fontShadow newWorldDetails newWorldDetails11 font3">“ 时 空 长</p>
				<p class="fontShadow newWorldDetails newWorldDetails12 font">星 夜 凉</p>
				<p class="fontShadow newWorldDetails newWorldDetails13 font6">我 不 畏 高 飞</p>
				<p class="fontShadow newWorldDetails newWorldDetails14 font7">天 尽 头</p>
				<p class="fontShadow newWorldDetails newWorldDetails15 font5">问 苍 茫 ”</p>
				<p class="fontShadow newWorldDetails newWorldDetails16 font3">“ 人 生</p>
				<p class="fontShadow newWorldDetails newWorldDetails17 font7">渺 渺</p>
				<p class="fontShadow newWorldDetails newWorldDetails18 font">一 种 脚 步</p>
				<p class="fontShadow newWorldDetails newWorldDetails19 font5">入 一 山 ”</p>
				<p class="fontShadow newWorldDetails newWorldDetails20 font7">“ 反 复 上</p>
				<p class="fontShadow newWorldDetails newWorldDetails21 font3">演</p>
				<p class="fontShadow newWorldDetails newWorldDetails22 font6">难 怪 片 刻 新 鲜 是</p>
				<p class="fontShadow newWorldDetails newWorldDetails23 font5">假 象 ”</p>
				<p class="fontShadow newWorldDetails newWorldDetails24 font3">“ 旁 观</p>
				<p class="fontShadow newWorldDetails newWorldDetails25 font6">都 是 一 时</p>
				<p class="fontShadow newWorldDetails newWorldDetails26 font5">幸 免</p>
				<p class="fontShadow newWorldDetails newWorldDetails27 font7">才 叫 历 史 ”</p>
				<img class="home NewWorldAlbum" src={SongNewWorldDetails} alt="SongNewWorldDetails"/>
				<p class="fontShadow newWorldDetails newWorldDetails28 font7">《 新 世 界 》</p>
				<button class="backButton font3" onClick={onClick}>回</button>
			</div>
		</Fragment>
	)
}

export { SongNewWorld }