import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import HAlbum from './HAlbum.PNG'; // Image from https://baike.baidu.com/item/H/20441876
import './NewWorldAlbumStyle.css';

function H() {
	let navigate = useHistory();

	const intoH = () => {
		navigate.push('/H');
	}

	const onClick = () => {
		if (window.history.state) {
			window.history.back();
		}
		else {
			navigate.push('/');
		}
	}

	return (
		<Fragment>
			<div class="containerH fontShadow">
				<p class="fontShadow HAlbumLyrics HAlbumLyrics1 font2">春 风 来 临</p>
				<p class="fontShadow HAlbumLyrics HAlbumLyrics2 font">万 物 生 长</p>
				<p class="fontShadow HAlbumLyrics HAlbumLyrics3 font1">我 试 图 穿 越 无 限 的</p>
				<p class="fontShadow HAlbumLyrics HAlbumLyrics4 font7">虚 空</p>
				<p class="fontShadow HAlbumLyrics HAlbumLyrics5 font">却 沦 为</p>
				<p class="fontShadow HAlbumLyrics HAlbumLyrics6 font1">化 成 风</p>
				<p class="fontShadow HAlbumLyrics HAlbumLyrics7 font5">一 场 空</p>
				<p class="fontShadow HAlbumLyrics HAlbumLyrics8 font5">世 界</p>
				<p class="fontShadow HAlbumLyrics HAlbumLyrics9 font">难 道 就 是 个 圈 套</p>
				<p class="fontShadow HAlbumLyrics HAlbumLyrics10 font7">爱 玩 </p>
				<p class="fontShadow HAlbumLyrics HAlbumLyrics11 font2">天 性 任 凭 你 管 不 了</p>
				<p class="fontShadow HAlbumLyrics HAlbumLyrics12 font">信 念 拔 地 而 起</p>
				<p class="fontShadow HAlbumLyrics HAlbumLyrics13 font4 font">But if forever lasts til now</p>
				<p class="fontShadow HAlbumLyrics HAlbumLyrics14 font4 font3">Alright</p>
				
				<img class="HAlbumCover" src={HAlbum} alt="HAlbum" onClick={intoH}/>
				<p class="fontShadow NewWorldAlbumLyrics NewWorldAlbumLyrics37 font6 horizontalWriting"><i>[ 点击专辑封面进入新世界 ]</i></p>

				<p class="fontShadow HAlbumLyrics HAlbumLyrics15 font7">不 受 现 实 的 约 束</p>
				<p class="fontShadow HAlbumLyrics HAlbumLyrics16 font2">也 想 与 流 星 共</p>
				<p class="fontShadow HAlbumLyrics HAlbumLyrics17 font5">舞</p>
				<p class="fontShadow HAlbumLyrics HAlbumLyrics18 font">不 要 怕</p>
				<p class="fontShadow HAlbumLyrics HAlbumLyrics19 font3">往 前 走</p>
				<p class="fontShadow HAlbumLyrics HAlbumLyrics20 font">有 专 属 的 磁 场 记 号 从</p>
				<p class="fontShadow HAlbumLyrics HAlbumLyrics21 font5">不 做 比 较</p>
				<p class="fontShadow HAlbumLyrics HAlbumLyrics22 font2">恍 惚 太 久</p>
				<p class="fontShadow HAlbumLyrics HAlbumLyrics23 font">我 离 孤 单</p>
				<p class="fontShadow HAlbumLyrics HAlbumLyrics24 font3">几 公 里</p>
				<p class="fontShadow HAlbumLyrics HAlbumLyrics25 font2">为 你 而 做</p>
				<p class="fontShadow HAlbumLyrics HAlbumLyrics26 font7">的 改 变</p>
				<p class="fontShadow HAlbumLyrics HAlbumLyrics27 font2">有 时 很 远 有 时 很</p>
				<p class="fontShadow HAlbumLyrics HAlbumLyrics28 font5">近</p>
				<p class="fontShadow HAlbumLyrics HAlbumLyrics29 font7">我 告 诉 自 己</p>
				<p class="fontShadow HAlbumLyrics HAlbumLyrics30 font1">这 是 真 的 这</p>
				<p class="fontShadow HAlbumLyrics HAlbumLyrics31 font5">不 是 梦</p>
				
				<button class="albumHome font" onClick={onClick}>回</button>
				
			</div>
		</Fragment>
	)
}

export { H }