import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import AliensAlbum from './AliensAlbum.JPG'; // Image from https://baike.baidu.com/item/异类/18720884
import './NewWorldAlbumStyle.css';

function Aliens() {
	let navigate = useHistory();

	const intoAliensAlbum = () => {
		navigate.push('/Aliens');
	}

	const onClick = () => {
		if (window.history.state) {
			window.history.back();
		}
		else {
			navigate.push('/');
		}
	}

	return (
		<Fragment>
			<div class="containerAliens fontShadow">
				<p class="fontShadow AliensAlbumLyrics AliensAlbumLyrics1 font7">原 来</p>
				<p class="fontShadow AliensAlbumLyrics AliensAlbumLyrics2 font3">一 颗 心</p>
				<p class="fontShadow AliensAlbumLyrics AliensAlbumLyrics3 font">太 脆 弱</p>
				<p class="fontShadow AliensAlbumLyrics AliensAlbumLyrics4 font5">回 忆</p>
				<p class="fontShadow AliensAlbumLyrics AliensAlbumLyrics5 font7">还 在 煽 风 点 火</p>
				<p class="fontShadow AliensAlbumLyrics AliensAlbumLyrics6 font1">你 的 反 义 词</p>
				<p class="fontShadow AliensAlbumLyrics AliensAlbumLyrics7 font7">是 我</p>
				<p class="fontShadow AliensAlbumLyrics AliensAlbumLyrics8 font6">怀 念 那 时 你</p>
				<p class="fontShadow AliensAlbumLyrics AliensAlbumLyrics9 font3">安 静 陪 着 我</p>
				<p class="fontShadow AliensAlbumLyrics AliensAlbumLyrics10 font">多 么 虚 幻</p>
				<p class="fontShadow AliensAlbumLyrics AliensAlbumLyrics11 font2">多 么 无 聊</p>
				<p class="fontShadow AliensAlbumLyrics AliensAlbumLyrics12 font7">多 么 荒 唐</p>
				<p class="fontShadow AliensAlbumLyrics AliensAlbumLyrics13 font">每 一 个 人 都 在</p>
				<p class="fontShadow AliensAlbumLyrics AliensAlbumLyrics14 font3">扮 演</p>
				<p class="fontShadow AliensAlbumLyrics AliensAlbumLyrics15 font">懂 的 人 自 然 能 看 透</p>
				<p class="fontShadow AliensAlbumLyrics AliensAlbumLyrics16 font5">你 没 资 格</p>
				<p class="fontShadow AliensAlbumLyrics AliensAlbumLyrics17 font1">评 头 论 足</p>
				
				<img class="AliensAlbumCover" src={AliensAlbum} alt="HAlbum" onClick={intoAliensAlbum}/>
				<p class="fontShadow NewWorldAlbumLyrics NewWorldAlbumLyrics37 font6 horizontalWriting"><i>[ 点击专辑封面进入新世界 ]</i></p>

				<p class="fontShadow AliensAlbumLyrics AliensAlbumLyrics18 font">我 根 本 不 受 谁 的 威 胁</p>
				<p class="fontShadow AliensAlbumLyrics AliensAlbumLyrics19 font3">极 度 放 纵</p>
				<p class="fontShadow AliensAlbumLyrics AliensAlbumLyrics20 font">极 度 温 柔</p>
				<p class="fontShadow AliensAlbumLyrics AliensAlbumLyrics21 font">那 又 怎 样</p>
				<p class="fontShadow AliensAlbumLyrics AliensAlbumLyrics22 font5">我 不 追 究</p>
				<p class="fontShadow AliensAlbumLyrics AliensAlbumLyrics23 font1">世 界 像 一 个</p>
				<p class="fontShadow AliensAlbumLyrics AliensAlbumLyrics24 font3">动 物 乐 园</p>
				<p class="fontShadow AliensAlbumLyrics AliensAlbumLyrics25 font7">没 想 辩 解 太 多</p>
				<p class="fontShadow AliensAlbumLyrics AliensAlbumLyrics26 font2">误 解 不 愿 反 驳</p>
				<p class="fontShadow AliensAlbumLyrics AliensAlbumLyrics27 font7">我 想 要</p>
				<p class="fontShadow AliensAlbumLyrics AliensAlbumLyrics28 font1">世 界 拥 有 更 多 欢 乐</p>
				<p class="fontShadow AliensAlbumLyrics AliensAlbumLyrics29 font2">谁 知</p>
				<p class="fontShadow AliensAlbumLyrics AliensAlbumLyrics30 font7">这 样</p>
				<p class="fontShadow AliensAlbumLyrics AliensAlbumLyrics31 font">矗 立</p>
				<p class="fontShadow AliensAlbumLyrics AliensAlbumLyrics32 font3">许 多 年</p>
				<p class="fontShadow AliensAlbumLyrics AliensAlbumLyrics33 font1">也 记 得</p>
				<p class="fontShadow AliensAlbumLyrics AliensAlbumLyrics34 font6">你 是 我 的</p>
				<p class="fontShadow AliensAlbumLyrics AliensAlbumLyrics35 font5">宝 贝</p>

				<button class="albumHome font" onClick={onClick}>回</button>
				
			</div>
		</Fragment>
	)
}

export { Aliens }