import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import SongETDetails from './SongETDetails.JPG'; // Image from 华晨宇工作室微博
import './NewWorldStyle.css';


function SongET() {
	let navigate = useHistory();

	const onClick = () => {
		if (window.history.state) {
			window.history.back();
		}
		else {
			navigate.push('/');
		}
	}

	return (
		<Fragment>
			<div class="SongContainer">
				<p class="fontShadow etDetails etDetails1  font7">“ 每 当 我 还 愿 意</p>
				<p class="fontShadow etDetails etDetails2  font6">伸 手</p>
				<p class="fontShadow etDetails etDetails3  font3">靠 近</p>
				<p class="fontShadow etDetails etDetails4  font">有 块 阴 影 拽 着 我</p>
				<p class="fontShadow etDetails etDetails5  font2">别 再</p>
				<p class="fontShadow etDetails etDetails6  font6">拽 着 我 ”</p>
				<p class="fontShadow etDetails etDetails7  font">“ 我</p>
				<p class="fontShadow etDetails etDetails8  font6">虽 然 不 说</p>
				<p class="fontShadow etDetails etDetails9  font1">至 少 有 你</p>
				<p class="fontShadow etDetails etDetails10 font7">在 这 陪 着 我 ”</p>
				<p class="fontShadow etDetails etDetails11 font">“ 你 就 是 你</p>
				<p class="fontShadow etDetails etDetails12 font3">最 好 的 你</p>
				<p class="fontShadow etDetails etDetails13 font5">没 人 能 代 替 ”</p>
				<p class="fontShadow etDetails etDetails14 font7">“ 我 等 到 了</p>
				<p class="fontShadow etDetails etDetails15 font3">这 礼 物 ”</p>
				<p class="fontShadow etDetails etDetails16 font6">“ 这 个 结 局 是 我 的</p>
				<p class="fontShadow etDetails etDetails17 font5">期 待 ”</p>
				<img class="home NewWorldAlbum" src={SongETDetails} alt="SongETDetails"/>
				<p class="fontShadow etDetails etDetails18 font7">《 与 火 星 的 孩 子 对 话 》</p>
				<button class="backButton font3" onClick={onClick}>回</button>
			</div>
		</Fragment>
	)
}

export { SongET }