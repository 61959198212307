import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import SongSplitDetails from './SongSplitDetails.JPG'; // Image from 华晨宇工作室微博
import './NewWorldStyle.css';


function SongSplit() {
	let navigate = useHistory();

	const onClick = () => {
		if (window.history.state) {
			window.history.back();
		}
		else {
			navigate.push('/');
		}
	}

	return (
		<Fragment>
			<div class="SongContainer">
				<p class="fontShadow splitDetails splitDetails1  font2">“ 欣 喜</p>
				<p class="fontShadow splitDetails splitDetails2  font6">愤 怒</p>
				<p class="fontShadow splitDetails splitDetails3  font1">欢 乐</p>
				<p class="fontShadow splitDetails splitDetails4  font7">悲 哀 ”</p>
				<p class="fontShadow splitDetails splitDetails5  font3">“ 洪 荒 宇 宙</p>
				<p class="fontShadow splitDetails splitDetails6  font7">千 变 万 化 ”</p>
				<p class="fontShadow splitDetails splitDetails7  font">“ 由 我 主 宰</p>
				<p class="fontShadow splitDetails splitDetails8  font5">任 我</p>
				<p class="fontShadow splitDetails splitDetails9  font6">主 宰 ”</p>
				<p class="fontShadow splitDetails splitDetails10 font7">“ 若</p>
				<p class="fontShadow splitDetails splitDetails11 font1">人 能 够</p>
				<p class="fontShadow splitDetails splitDetails12 font6">接 受</p>
				<p class="fontShadow splitDetails splitDetails13 font">注 定 沉 沦</p>
				<p class="fontShadow splitDetails splitDetails14 font7">那 些</p>
				<p class="fontShadow splitDetails splitDetails15 font5">宿 命 ”</p>
				<p class="fontShadow splitDetails splitDetails16 font7">“ 当</p>
				<p class="fontShadow splitDetails splitDetails17 font6">又 变 的</p>
				<p class="fontShadow splitDetails splitDetails18 font3">安 静 了</p>
				<p class="fontShadow splitDetails splitDetails19 font">这 一 切</p>
				<p class="fontShadow splitDetails splitDetails20 font6">多 么</p>
				<p class="fontShadow splitDetails splitDetails21 font5">美</p>
				<p class="fontShadow splitDetails splitDetails22 font">星 星 都</p>
				<p class="fontShadow splitDetails splitDetails23 font7">坠 毁</p>
				<p class="fontShadow splitDetails splitDetails24 font6">漆 黑</p>
				<p class="fontShadow splitDetails splitDetails25 font6">我</p>
				<p class="fontShadow splitDetails splitDetails26 font">像 幅 拼 图</p>
				<p class="fontShadow splitDetails splitDetails27 font5">丢</p>
				<p class="fontShadow splitDetails splitDetails28 font6">了 一 块</p>
				<p class="fontShadow splitDetails splitDetails29 font7">多 刺 眼</p>
				<p class="fontShadow splitDetails splitDetails30 font6">的</p>
				<p class="fontShadow splitDetails splitDetails31 font5">空 白</p>
				<p class="fontShadow splitDetails splitDetails32 font3">不 停 地</p>
				<p class="fontShadow splitDetails splitDetails33 font6">在 懊 悔</p>
				<p class="fontShadow splitDetails splitDetails34 font">直 到</p>
				<p class="fontShadow splitDetails splitDetails35 font3">迷 失</p>
				<p class="fontShadow splitDetails splitDetails36 font7">自 己 ”</p>
				<img class="home NewWorldAlbum" src={SongSplitDetails} alt="SongSplitDetails"/>
				<p class="fontShadow splitDetails splitDetails37 font7">《 七 重 人 格 》</p>
				<button class="backButton font3" onClick={onClick}>回</button>
			</div>
		</Fragment>
	)
}

export { SongSplit }