import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import SongTreeDetails from './SongTreeDetails.JPG'; // Image from 华晨宇工作室微博
import './NewWorldStyle.css';


function SongTree() {
	let navigate = useHistory();

	const onClick = () => {
		if (window.history.state) {
			window.history.back();
		}
		else {
			navigate.push('/');
		}
	}

	return (
		<Fragment>
			<div class="SongContainer">
				<p class="fontShadow treeDetails treeDetails1  font">“ 巨 浪 席 卷</p>
				<p class="fontShadow treeDetails treeDetails2  font3">狼 藉 一 片</p>
				<p class="fontShadow treeDetails treeDetails3  font6">都 惶 恐 ”</p>
				<p class="fontShadow treeDetails treeDetails4  font7">“ 我 害 怕 黑 暗 与 伤 痛</p>
				<p class="fontShadow treeDetails treeDetails5  font">踩 着 废 墟</p>
				<p class="fontShadow treeDetails treeDetails6  font6">越 陷 越</p>
				<p class="fontShadow treeDetails treeDetails7  font5">深</p>
				<p class="fontShadow treeDetails treeDetails8  font7">都 落</p>
				<p class="fontShadow treeDetails treeDetails9  font5">空 ”</p>
				<p class="fontShadow treeDetails treeDetails10 font7">“ 我 淹 没 在</p>
				<p class="fontShadow treeDetails treeDetails11 font5">万 人</p>
				<p class="fontShadow treeDetails treeDetails12 font6">声 响 ”</p>
				<p class="fontShadow treeDetails treeDetails13 font">“ 我 感 应 这</p>
				<p class="fontShadow treeDetails treeDetails14 font7">海</p>
				<p class="fontShadow treeDetails treeDetails15 font3">啸</p>
				<p class="fontShadow treeDetails treeDetails16 font6">悲 凉 ”</p>
				<img class="home NewWorldAlbum" src={SongTreeDetails} alt="SongTreeDetails"/>
				<p class="fontShadow treeDetails treeDetails17 font7">《 神 树 》</p>
				<button class="backButton font3" onClick={onClick}>回</button>
			</div>
		</Fragment>
	)
}

export { SongTree }