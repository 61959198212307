import React, { Fragment } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import REACTGA from 'react-ga';
import './style.css';
import { Home, NewWorld, H, Aliens, Gift } from './Home';
import { About, Quotes, Redirect, Love } from './About';
import { 
    AlbumNewWorld, 
    SongBullfight, 
    SongLove, 
    SongHouse, 
    SongET, 
    SongSplit, 
    SongTree, 
    SongArrival, 
    SongNewWorld 
} from './Albums';

// var index = 0;
// var welcome = " welcome home! ❤";

// function say() {
// 	if (index < welcome.length) {
// 		document.getElementById('welcome').innerHTML += welcome[index];
// 		setTimeout(say, 100);
// 		index++;
// 	}
// }

function REACTGASetup() {
    REACTGA.initialize('UA-167583357-1');
    REACTGA.pageview('/home');
}

function App() {
  return (
    <Fragment>
    {REACTGASetup()}
    <Router>
      <Switch>
        <Route path="/" exact component={Home}></Route>
        {/*<Route path="/new" exact component={}></Route>*/}
        <Route path="/AlbumNewWorld" exact component={NewWorld}></Route>
        <Route path="/AlbumH" exact component={H}></Route>
        <Route path="/AlbumAliens" exact component={Aliens}></Route>
        <Route path="/AlbumGift" exact component={Gift}></Route>
        <Route path="/about" exact component={About}></Route>
        <Route path="/hcy" exact component={Quotes}></Route>
        <Route path="/BORNToLove" exact component={Love}></Route>
        <Route path="/NewWorld" exact component={AlbumNewWorld}></Route>
        <Route path="/NewWorld/songBullfight" exact component={SongBullfight}></Route>
        <Route path="/NewWorld/songLove" exact component={SongLove}></Route>
        <Route path="/NewWorld/songMadhouse" exact component={SongHouse}></Route>
        <Route path="/NewWorld/songET" exact component={SongET}></Route>
        <Route path="/NewWorld/songSplit" exact component={SongSplit}></Route>
        <Route path="/NewWorld/songTree" exact component={SongTree}></Route>
        <Route path="/NewWorld/songArrival" exact component={SongArrival}></Route>
        <Route path="/NewWorld/songNewWorld" exact component={SongNewWorld}></Route>
        <Route path="/*" exact component={Redirect}></Route>
      </Switch>
    </Router>
    </Fragment>
  )
}

export default App
