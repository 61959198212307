import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import mer from './mer.JPG'; // Image from 华晨宇工作室微博
import SongBullfight from './SongBullfight.JPG'; // Image from 华晨宇工作室微博
import SongLove from './SongLove.JPG'; // Image from 华晨宇工作室微博
import SongHouse from './SongHouse.JPG'; // Image from 华晨宇工作室微博
import SongET from './SongET.JPG'; // Image from 华晨宇工作室微博
import SongSplit from './SongSplit.JPG'; // Image from 华晨宇工作室微博
import SongTree from './SongTree.JPG'; // Image from 华晨宇工作室微博
import SongArrival from './SongArrival.JPG'; // Image from 华晨宇工作室微博
import SongNewWorld from './SongNewWorld.JPG'; // Image from 华晨宇工作室微博


function AlbumNewWorld() {
	let navigate = useHistory();

	const onClick = () => {
		if (window.history.state) {
			window.history.back();
		}
		else {
			navigate.push('/');
		}	
	}

	const songBullfightDetails = () => {
		navigate.push('/NewWorld/songBullfight');
	}

	const songLoveDetails = () => {
		navigate.push('/NewWorld/songLove');
	}

	const songHouseDetails = () => {
		navigate.push('/NewWorld/songMadhouse');
	}

	const songETDetails = () => {
		navigate.push('/NewWorld/songET');
	}

	const songSplitDetails = () => {
		navigate.push('/NewWorld/songSplit');
	}

	const songTreeDetails = () => {
		navigate.push('/NewWorld/songTree');
	}

	const songArrivalDetails = () => {
		navigate.push('/NewWorld/songArrival');
	}

	const songNewWorldDetails = () => {
		navigate.push('/NewWorld/songNewWorld');
	}

	return (
		<Fragment>
			<div class="SongContainer">
				<img class="home NewWorldAlbum" src={mer} alt="mer"/>
				<img class="songBullfight" src={SongBullfight} alt="SongBullfight" onClick={songBullfightDetails}/>
				<img class="songLove" src={SongLove} alt="SongLove" onClick={songLoveDetails}/>
				<img class="songHouse" src={SongHouse} alt="SongHouse" onClick={songHouseDetails}/>
				<img class="songET" src={SongET} alt="SongET" onClick={songETDetails}/>
				<img class="songSplit" src={SongSplit} alt="SongSplit" onClick={songSplitDetails}/>
				<img class="songTree" src={SongTree} alt="SongTree" onClick={songTreeDetails}/>
				<img class="songArrival" src={SongArrival} alt="SongArrival" onClick={songArrivalDetails}/>
				<img class="songNewWorld" src={SongNewWorld} alt="SongNewWorld" onClick={songNewWorldDetails}/>
				<button class="backButton font" onClick={onClick}>回</button>
			</div>
		</Fragment>
	)
}

export { AlbumNewWorld }