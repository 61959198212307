import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import who from './520.JPG'; // Image from 华晨宇工作室微博

function Love() {
	let navigate = useHistory();

	const onClick = () => {
		navigate.push('/BORNToLove/hcyMARS');
	}

	const backHome = () => {
		navigate.push('/');
	}

	return (
		<Fragment>
			<div class="container520 back520 fontShadow">
				<p class="fontShadow messageLove messageLove1 font7">“ 你 知 道</p>
				<p class="fontShadow messageLove messageLove2 font6">这 个</p>
				<p class="fontShadow messageLove messageLove3 font1">世 界 上</p>
				<p class="fontShadow messageLove messageLove4 font7">最 畅 销 的 歌 曲</p>
				<p class="fontShadow messageLove messageLove5 font6">是 什 么 吗 ？</p>
				<p class="fontShadow messageLove messageLove6 font2">HAPPY BIRTHDAY TO YOU 啊</p>
				<p class="fontShadow messageLove messageLove7 font">我 可 以 唱 给 你 听 啊 ”</p>
				<p class="fontShadow messageLove messageLove8 font">5 2 0</p>
				<p class="fontShadow messageLove messageLove9 font7">“ 生 日 快 乐 ！”</p>
				<p class="fontShadow messageLove messageLove10 font7">@ 华 晨 宇</p>
				<p class="fontShadow messageLove messageLove11 font7">火 星 空 间 站 M A R S</p>
				
				<div>
					<img class="hcy520" src={who} alt="who" onClick={onClick}/>
				</div>
				<p class="fontShadow messageLove messageLove11 font7">火 星 空 间 站 M A R S</p>
				<button class="backButton font" onClick={backHome}>BORN <br></br>TO LOVE</button>
				
			</div>
		</Fragment>
	)
}

export { Love }