import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import SongArrivalDetails from './SongArrivalDetails.JPG'; // Image from 华晨宇工作室微博
import './NewWorldStyle.css';


function SongArrival() {
	let navigate = useHistory();

	const onClick = () => {
		if (window.history.state) {
			window.history.back();
		}
		else {
			navigate.push('/');
		}
	}

	return (
		<Fragment>
			<div class="SongContainer">
				<p class="fontShadow arrivalDetails arrivalDetails1  font">“ 入</p>
				<p class="fontShadow arrivalDetails arrivalDetails2  font5">梦 境</p>
				<p class="fontShadow arrivalDetails arrivalDetails3  font7">之 后 请</p>
				<p class="fontShadow arrivalDetails arrivalDetails4  font3">小 心</p>
				<p class="fontShadow arrivalDetails arrivalDetails5  font6">秉 烛</p>
				<p class="fontShadow arrivalDetails arrivalDetails6  font">夜 游 ”</p>
				<p class="fontShadow arrivalDetails arrivalDetails7  font1">“ 前 瞻</p>
				<p class="fontShadow arrivalDetails arrivalDetails8  font6">又</p>
				<p class="fontShadow arrivalDetails arrivalDetails9  font7">顾 后 ”</p>
				<p class="fontShadow arrivalDetails arrivalDetails10 font3">“ 万 象</p>
				<p class="fontShadow arrivalDetails arrivalDetails11 font7">迎 一 杯 枯 荣</p>
				<p class="fontShadow arrivalDetails arrivalDetails12 font6">双 手</p>
				<p class="fontShadow arrivalDetails arrivalDetails13 font5">捧 新 芽</p>
				<p class="fontShadow arrivalDetails arrivalDetails14 font">降 落 ”</p>
				<p class="fontShadow arrivalDetails arrivalDetails15 font7">“ 学 不 会</p>
				<p class="fontShadow arrivalDetails arrivalDetails16 font5">懵 懂 ”</p>
				<p class="fontShadow arrivalDetails arrivalDetails17 font3">“ 寂 静</p>
				<p class="fontShadow arrivalDetails arrivalDetails18 font7">消 散</p>
				<p class="fontShadow arrivalDetails arrivalDetails19 font5">曙 光</p>
				<p class="fontShadow arrivalDetails arrivalDetails20 font">暗 涌 ”</p>
				<img class="home NewWorldAlbum" src={SongArrivalDetails} alt="SongArrivalDetails"/>
				<p class="fontShadow arrivalDetails arrivalDetails21 font7">《 降 临 》</p>
				<button class="backButton font3" onClick={onClick}>回</button>
			</div>
		</Fragment>
	)
}

export { SongArrival }